* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: Inter, sans-serif;
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slideInRight {
  opacity: 1;
}

button,
a,
input,
textarea {
  font-family: Inter, sans-serif;
}

button,
a {
  cursor: pointer;
}
